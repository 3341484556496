<!--
File: TreatmentMatrix.vue
Description: shows the Treatment Matrix using the vue-pivot-table Component.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item btn-row md-small-size-100">
            <!--md-button class="md-success" @click="addItem"> {{ $t('buttons.add') }} </md-button-->
            <md-button class="md-success" @click="print" :disabled="isDataLoading"> {{ $t('buttons.print') }} </md-button>
            <!--md-button class="md-raised md-success" @click="exportToExcel" :disabled="isDataLoading">
              {{ $t('buttons.excel_export') }}
            </md-button-->
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <span id="matrix" v-html="treatmentMatrix"></span>
      </md-card-content>
    </md-card>

    <div>
      <md-dialog :md-active.sync="showTreatmentSelectionDlg" :md-click-outside-to-close="false">
        <md-dialog-title>{{ $t('label.change_treatment') }}
          <md-button class='md-simple md-just-icon md-round modal-default-button'
            @click='showTreatmentSelectionDlg = false'>
            <md-icon>clear</md-icon>
          </md-button>
        </md-dialog-title>
        <md-dialog-content>
          <TreatmentsDropdown v-model="selectedTreatment" :initial_value="selectedTreatment" @input="onTreatmentChange"
            :label="$t('treatment.treatment_key')" />
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-success" @click="saveTreatmentType">{{ $t('buttons.save') }}</md-button>
          <md-button class="md-primary" @click="showTreatmentSelectionDlg = false">{{ $t('buttons.close') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import TreatmentsDropdown from '@/pages/Components/TreatmentsDropdown.vue'
  import printReport from '@/mixins/printMixin'
  import messagesMixin from '@/mixins/messagesMixin'

  export default {
    name: 'treatment-matrix',
    mixins: [printReport, messagesMixin],

    data() {
      return {
        showTreatmentSelectionDlg: false,
        selectedMatrixId: null,
        selectedTreatment: null,
        treatmentDescr: null,
        isDataLoading: true,
      }
    },

    components: {
      TreatmentsDropdown,
    },

    mounted() {
      this.reloadData()
    },

    methods: {
      async reloadData() {
        this.isDataLoading = true
        await this.$store.dispatch('LOAD_TREATMENT_MATRIX_PIVOT_HTML')
        this.isDataLoading = false
      },

      addItem() {
        console.log('Add item')
      },

      editItem(item) {
        //console.log("Edit:", item.item_id)
        this.selectedMatrixId = item.item_id
        this.selectedTreatment = item.treatment
        this.showTreatmentSelectionDlg = true
      },

      deleteItem(item) {
        const ind = this.treatmentMatrix.findIndex((el) => el.treatment_matrix_id == item.item_id)
        if (ind >= 0) this.treatmentMatrix.splice(ind, 1)
        console.log("deleted (ind, id, key):", ind, item.treatment, item.treatment_key)
      },

      onTreatmentChange(id, descr) {
        //console.log(id, descr)
        this.treatmentDescr = descr
      },

      async saveTreatmentType() {
        const item = this.treatmentMatrix.find(el => el.treatment_matrix_id == this.selectedMatrixId)
        let status = ''
        if (!item) {
          status = 'error'
        } else {
          const updItem = {
            rutting_from: item.rutting_from,
            rutting_to: item.rutting_to,
            iri_from: item.iri_from,
            iri_to: item.iri_to,
            aadt_from: item.aadt_from,
            aadt_to: item.aadt_to,
            cracking_from: item.cracking_from,
            cracking_to: item.cracking_to,
            potholes_from: item.potholes_from,
            potholes_to: item.potholes_to,
            fk_treatment_type: this.selectedTreatment
          }

          try {
            await this.$store.dispatch(`UPD_TREATMENT_MATRIX`, { id: this.selectedMatrixId, item: updItem })
          } catch (err) {
            status = err
          }
        }
        this.savedMessage(status, this.$t('route.treatment_matrix'), this.treatmentDescr)
        this.showTreatmentSelectionDlg = false
        this.reloadData()
      },

      print() {
        this.showSpinner = true
        this.printReport(this.getPrintHeader)
        this.showSpinner = false
      },

      async exportToExcel() {
        this.$store.dispatch('LOAD_TREATMENT_MATRIX_PIVOT_EXCEL').then(res => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res]))
          link.download = "treatment_matrix.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
      },

      onChange(value) {
        //console.log(value)
      }
    },

    computed: {
      ...mapState({
        pivot: (state) => state.Treatments.treatmentMatrixPivot,
      }),
      ...mapGetters(['treatmentMatrixEditable']),

      treatmentMatrix() {
        const replacements = {
          key: this.$t('label.score'),
          aadt: this.$t('condition.aadt'),
          cracking: this.$t('label.cracking'),
          potholes: this.$t('label.potholes'),
          rutting: this.$t('condition.rutting'),
          iri: this.$t('condition.iri'),
        };

        /* TODO: finish it (now it rotates but moves up):
        // Rotate the first column values
        const html = this.pivot.replace(/<tr>\s*<th rowspan="9" valign="top">(.*?)<\/th>/gi, (match, content) => {
          //console.log('match, content', match, content)
          if (content.trim().toLowerCase() !== 'aadt') {
            return match.replace(content, `<div class="text_rotate">${content}</div>`);
          }
          return match;
        });
        */

        // Change the key to the localized versions
        return this.pivot.replace(new RegExp(Object.keys(replacements).join('|'), 'gi'), match => {
          return replacements[match.toLowerCase()];
        });
        /*
                //<div class="text_rotate">{{ value }}</div>
                        <!-- Added check for treatmentMatrixEditable after several requests from Meyyappan to enable/disable editing -->
                      <md-button v-if="treatmentMatrixEditable" class="md-just-icon md-default md-simple"
                        :title="$t('buttons.edit')" @click.native="editItem(value)">
                        <md-icon>edit</md-icon>
                      </md-button>
                      <!--md-button v-if="treatmentMatrixEditable" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                        @click.stop.prevent="deleteItem(value)">
                        <md-icon>delete</md-icon>
                      </md-button-->
          */
      },
      getPrintHeader() {
        const today = new Date().toLocaleDateString()

        const matrixElement = this.$el.querySelector('#matrix');
        //remove Buttons
        const prtHtml = !matrixElement ? '' : matrixElement.innerHTML.replace(/<button[^>]*>.*?<\/button>/g, '');

        return `<h4 style='text-align:center'>${this.$t('label.reports_title')}</h4>
                                <h4 style='text-align:center'>${this.$t('route.treatment_matrix')} ${this.$t('label.as_on')}${today}</h4>
                                ${prtHtml}`
      },

    },
  }
</script>
<style lang="scss">
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5

.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.text_rotate {
  rotate: -90deg;
  white-space: pre;
  width: 50px;
  text-align: center;
  padding: 0px;
}

.value-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Отступ между значением и выпадающим списком */
}

#matrix table {
  border: 0.5px solid;
  border-collapse: collapse;
}

#matrix td,
#matrix th {
  border: 0.5px solid;
}

.flex-container {
  display: flex;
  align-items: center;
  /* Вертикальное выравнивание по центру */
  justify-content: space-between;
  /* Текст слева, кнопки справа */
  padding-left: 5px;
  /* Отступ для текста от левой границы */
  padding-right: 0px;
  /* Отступ для кнопок от правой границы */
}

.text-content {
  flex-grow: 1;
  /* Заставляет текст занять всё оставшееся пространство */
  margin-right: 0px;
  /* Добавляем небольшой отступ между текстом и кнопками */
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0px;
  /* Отступ между кнопками */
}

.md-button {
  min-width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>